<template>
  <form action="" class="w-1/2 hidden space-y-6">
    <div>
      <label for="firstName" class="block text-sm text-wrh-gray-dark mb-2">Type your old password</label>
      <input type="password" id="oldPass" class="wrh-input border-wrh-gray-dark" />
    </div>
    <div>
      <label class="block text-sm text-wrh-gray-dark mb-2">Create a new password</label>
      <input type="password" class="wrh-input border-wrh-gray-dark" />
    </div>
    <div>
      <label class="block text-sm text-wrh-gray-dark mb-2">Create a new password</label>
      <input type="password" class="wrh-input border-wrh-gray-dark" />
    </div>
    <div class="pt-2 text-right">
      <button class="btn w-1/3 bg-wrh-blue-light text-white rounded uppercase py-2 focus:outline-none">
        Save
      </button>
    </div>
  </form>
</template>
