<template>
  <form action="" class="w-1/2 hidden space-y-6">
    <div class="pb-2">
      <label class="w-52 h-52 rounded-full bg-wrh-gray-light block" for="userImage"></label>
      <input type="file" class="invisible absolute" id="userImage" />
    </div>
    <div>
      <label for="firstName" class="block text-sm text-wrh-gray-dark mb-2">First Name</label>
      <input type="text" id="firstName" class="wrh-input border-wrh-gray-dark" />
    </div>
    <div>
      <label for="lastName" class="block text-sm text-wrh-gray-dark mb-2">Last Name</label>
      <input type="text" class="wrh-input border-wrh-gray-dark" id="lastName" />
    </div>
    <div>
      <label class="block text-sm text-wrh-gray-dark mb-2" for="cellPhone">Cell Phone</label>
      <input type="text" class="wrh-input border-wrh-gray-dark" id="cellPhone" />
    </div>
    <div>
      <label class="block text-sm text-wrh-gray-darkmb-2" for="email">Email Address</label>
      <input type="text" class="wrh-input border-wrh-gray-dark" id="email" />
    </div>
    <div class="pt-2">
      <button class="btn w-full bg-wrh-blue-light text-white rounded uppercase py-2 focus:outline-none">
        Update
      </button>
    </div>
  </form>
</template>
