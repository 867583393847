<template>
  <form action="" class="w-1/2 space-y-6 hidden">
    <div>
      <label for="name" class="block text-sm text-wrh-gray-dark mb-2">Name</label>
      <input type="text" id="name" class="wrh-input border-wrh-gray-dark" />
    </div>
    <div>
      <label class="block text-sm text-wrh-gray-dark mb-2">Phone</label>
      <input type="text" class="wrh-input border-wrh-gray-dark" />
    </div>
    <div>
      <label class="block text-sm text-wrh-gray-dark mb-2">Email Address</label>
      <input type="text" class="wrh-input border-wrh-gray-dark" />
    </div>
   <div class="pt-2 text-right">
      <button class="btn w-full bg-wrh-blue-light text-white rounded uppercase py-2 focus:outline-none">
        Save
      </button>
    </div>
  </form>
</template>
